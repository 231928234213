import classes from "./VerticalAlertControlCenter.module.css";
import Email from "../contact/Email";
import Telephone from "../contact/Telephone";
import ActionsBox from "../ActionsBox";
import { useState } from "react";
import Sms from "../contact/Sms";

const VerticalAlertControlCenter = ({ alertDetails }) => {
  const [actionFocus, setActionFocus] = useState("email_form");

  const actionFocusHandler = (value) => setActionFocus(value);

  const sumbitHandler = async (event) => {
    event.preventDefault();
  };

  return (
    <div
      className={classes.alert_control_center_container}
      onSubmit={sumbitHandler}
    >
      <ActionsBox alertDetails={alertDetails} />
      <div className={classes.menu_form}>
        <div
          className={actionFocus === "email_form" ? classes.active : null}
          onClick={() => actionFocusHandler("email_form")}
        >
          E-mail
        </div>
        <div
          className={actionFocus === "telephone_form" ? classes.active : null}
          onClick={() => actionFocusHandler("telephone_form")}
        >
          Τηλέφωνο
        </div>
        <div
          className={actionFocus === "sms_form" ? classes.active : null}
          onClick={() => actionFocusHandler("sms_form")}
        >
          SMS
        </div>
      </div>

      {actionFocus === "email_form" && (
        <Email
          focus={true}
          historyTitle={"Ισορικό e-mail"}
          alertDetails={alertDetails}
        />
      )}
      {actionFocus === "telephone_form" && (
        <Telephone
          focus={true}
          historyTitle={"Ισορικό κλήσεων"}
          alertDetails={alertDetails}
        />
      )}

      {actionFocus === "sms_form" && (
        <Sms
          focus={true}
          historyTitle={"Ισορικό μηνυμάτων"}
          alertDetails={alertDetails}
        />
      )}
    </div>
  );
};

export default VerticalAlertControlCenter;
