import classes from "./WelcomeMessage.module.css";
import apple from "./../../assets/images/apple.png";
import android from "./../../assets/images/android.png";
import information from "./../../assets/images/information_project.png";
import zitsafund from "./../../assets/images/zitsafund.png";
import SvgTermsofuse2 from "./../../assets/icons/Termsofuse2";
import { useHistory } from "react-router-dom";

const sponsor_logo = zitsafund;
const WelcomeMessage = (props) => {
  const history = useHistory();
  return (
    <div className={classes.welcome_placeholder}>
      <p>
        Ο Δήμος {props.municipality} σας καλωσορίζει στην Πλατφόρμα Εξυπνης
        Πόλης.
      </p>
      <p> Ενημερωθείτε για τις συνθήκες που επικρατούν στην πόλη μας.</p>

      <div>
	   <div>
      <div className={classes.logoimgtxt}><img
          src={sponsor_logo}
          alt="interreg logo"
		  className={classes.epsa_logo}
        /><span className={classes.logotxt}>Με τη συγχρηματοδότηση της Ελλάδας και της Ευρωπαϊκής Ένωσης</span></div>
      </div>
      <div
          style={{ marginBottom: 5 }}
          onClick={() => history.push("/terms-of-use")}
        >
          <SvgTermsofuse2 width={35} />
          <p>Δήλωση Προσβασιμότητας</p>
        </div>
        <div
          onClick={() =>
            window.open("https://www.smartiscity.gr/dimos-zitsas/")
          }
        >
          <img src={information} alt={"information-application"} width={35} />
          <p>Πληροφορίες για το έργο</p>
        </div>
        <div>		
          <img src={apple} alt={"apple-application"} width={150} 
		    onClick={() =>
              window.open("https://apps.apple.com/tt/app/%CE%B4%CE%AE%CE%BC%CE%BF%CF%82-%CE%B6%CE%AF%CF%84%CF%83%CE%B1%CF%82/id1593597545")
          }/>
          <img src={android} alt={"android-application"} width={150} 
		    onClick={() =>
              window.open("https://play.google.com/store/apps/details?id=gr.dotsoft.cityzenapp.zitsa")
          }/>
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessage;
