import classes from "./Card.module.css";
import HoverCard from "../hoverCard/HoverCard";

const Card = (props) => {
  const sumbitHandler = async (event) => {
    event.preventDefault();
    if (props.onConfirm) props.onConfirm();
  };

  return (
    <form
      className={[classes.card_container, props.className].join(" ")}
      onSubmit={sumbitHandler}
      id={props.id}
    >
      <label>{props.title}</label>

      {props.children}

      <div className={classes.card_actions}>
        {props.historyTitle && (
          <div className={classes.history_container}>
            <span>Info</span>
            <HoverCard title={props.historyTitle} history={props.historyList} />
          </div>
        )}
        <button
          type={"button"}
          className={[classes.purge_button, classes.clear_button].join(" ")}
          onClick={() => props.onCancel && props.onCancel()}
        >
          x
        </button>
        <button
          type={"submit"}
          className={[classes.purge_button, classes.send_button].join(" ")}
        >
          {props.confirmNameBtn ? props.confirmNameBtn : "Αποστολή"}
        </button>
      </div>
    </form>
  );
};

export default Card;
