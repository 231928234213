import classes from "./AlertClose.module.css";
import Title from "../../../UI/box/title/Title";
import { Fragment } from "react";

const AlertClose = (props) => (
  <Fragment>
    <Title
      className={[classes.bigger_title, props.className].join(" ")}
      element={
        <i
          id="draggable"
          className="im im-icon-Double-Tap"
          style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
        />
      }
    />
    <section className={[classes.alert_card_close, props.className].join(" ")}>
      <h1>{props.title}</h1>
    </section>
  </Fragment>
);

export default AlertClose;
