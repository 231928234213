import classes from "./HorizontalAlertsOptions.module.css";
import SearchInput from "../../UI/input/SearchInput";
import PopupContainer from "../../../containers/popup/PopupContainer";
import repeat from "./../../../assets/images/repeat.png";

const HorizontalAlertsOptions = (props) => {
  return (
    <div className={classes.popup_class}>
      <SearchInput />
      <PopupContainer className={classes.var_margin} />
      <img
        src={repeat}
        alt={"εκρεμμότητες"}
        className={classes.var_margin}
        style={{ cursor: "pointer" }}
        onClick={() => props.changePreview()}
      />
    </div>
  );
};

export default HorizontalAlertsOptions;
