import { Fragment } from "react";
import Title from "./../../UI/box/title/Title";
import SubTitle from "./../../UI/box/subtitle/SubTitle";
import { LoadingDot } from "../../UI/spinners/LoadingSpinners";

const UVForm = (props) => {
  return (
    <Fragment>
      {props.data && (
        <Fragment>
          <Title
            icon={"im im-icon-Envelope-2"}
            title={props.data.index_name}
            styleIcon={props.typeId === "5" && { color: "black" }}
            link={`/map?typeId=${props.typeId}`}
            infoContext={props.data && props.data.tooltip}
            element={
              <i
                id="draggable"
                className="im im-icon-Double-Tap"
                style={{
                  fontSize: "32px",
                  minWidth: "32px",
                  cursor: "pointer",
                }}
              />
            }
            id={props.typeId}
          />

          <SubTitle style={props.typeId === "5" ? { color: "white" } : {}}>
            <strong>
              {props.data.details[0].measurement && (
                <span>
                  Τιμή δείκτη: {parseFloat(props.data.details[0].measurement)}
                  &nbsp;|&nbsp;
                </span>
              )}
              {props.data.details[0].index_title}
            </strong>
          </SubTitle>
          <p style={{ fontSize: "14px", lineHeight: "1.5em" }}>
            {props.data.details[0].index_description}
          </p>
        </Fragment>
      )}

      {!props.data && <LoadingDot />}
    </Fragment>
  );
};

export default UVForm;
