import classes from "./Entity.module.css";

const Entity = (props) => {
  return (
    <div
      className={[classes.entity_container, props.className].join(" ")}
      style={{ ...props.style }}
    >
      {/* <div className={classes.title_container}>
        {props.icon && (
          <i className={props.icon} style={{ fontSize: "20px" }} />
        )}
      </div> */}
      {!props.svg && (
        <i
          className={props.icon}
          style={{
            fontSize: "20px",
            float: "left",
            color: props.colorIcon ? props.colorIcon : "inherit",
          }}
        />
      )}
      {props.svg && (
        <props.svg
          width={20}
          height={21}
          style={{
            fontSize: "20px",
            float: "left",
            color: props.colorIcon ? props.colorIcon : "inherit",
          }}
        />
      )}
     <div className={classes.information}>
        <span className={classes.entity_title}>{props.title}</span>
        <p className={classes.entity_addres}>{props.address}</p>
        {props.last_alert && (
          <p className={classes.entity_addres}>{props.last_alert}</p>
        )}
        {props.icons.map(
          (icon, index) =>
            icon && <img key={index} src={icon} alt={"info icons"} />
        )}
      </div>
    </div>
  );
};
export default Entity;
