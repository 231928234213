import LogTable from "../components/Table/LogTable";
import SearchTable from "../components/Table/SearchTable";
import LogTablePager from "../components/Table/LogTablePager";

const Log = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <SearchTable />
      <LogTable />
      <LogTablePager />
    </div>
  );
};

export default Log;
