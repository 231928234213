import classes from "./LogTablePager.module.css";
import { useHistory, useLocation } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";

const max = 10;
const LogTablePager = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const { search } = useLocation();
  const { pathname } = useLocation();
  const history = useHistory();

  const pageHandler = (goPageIndex) =>
    history.push(pathname + "?page=" + goPageIndex);

  useEffect(() => {
    const page = new URLSearchParams(search).get("page");

    if (!page || isNaN(page)) history.push(pathname);
    else {
      const pageInt = parseInt(page);
      if (pageInt < 1) history.push(pathname + "?page=" + 1);
      else if (pageInt > max) history.push(pathname + "?page=" + max);
      else setPageIndex(pageInt);
    }
  }, [search]);

  return (
    <div className={classes.log_table_pager_container}>
      {max > 1 && (
        <Fragment>
          <button
            disabled={pageIndex === 1}
            onClick={() => pageHandler(pageIndex - 1)}
          >
            Prev
          </button>
          <div>
            <button
              className={pageIndex === 1 ? classes.active : null}
              onClick={() => pageHandler(1)}
            >
              1
            </button>
            {pageIndex > 3 && <p>...</p>}
            {pageIndex > 2 && (
              <button onClick={() => pageHandler(pageIndex - 1)}>
                {pageIndex - 1}
              </button>
            )}
            {pageIndex > 1 && pageIndex < max && (
              <button
                className={pageIndex === pageIndex ? classes.active : null}
              >
                {pageIndex}
              </button>
            )}
            {pageIndex < max - 1 && (
              <button onClick={() => pageHandler(pageIndex + 1)}>
                {pageIndex + 1}
              </button>
            )}
            {max - pageIndex > 2 && <p>...</p>}

            <button
              className={pageIndex === max ? classes.active : null}
              onClick={() => pageHandler(max)}
            >
              {max}
            </button>
          </div>

          <button
            disabled={pageIndex === max}
            onClick={() => pageHandler(pageIndex + 1)}
          >
            Next
          </button>
        </Fragment>
      )}
    </div>
  );
};
export default LogTablePager;
