import classes from "./VerticalAlerts.module.css";
import VerticalTitlte from "./VerticalTitle";
import VerticalInfoContent from "./VerticalInfoContent";
import VerticalAlertContent from "./VerticalAlertContent";
import VerticalSwitch from "./VerticalSwitch";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useStore } from "../../../hooks/store";

const VerticalAlerts = () => {
  const { search } = useLocation();
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDetails, setAlertDetails] = useState({});

  const state = useStore()[0];

  useEffect(() => {
    const typeId = new URLSearchParams(search).get("typeId");

    const typeTitle = state.types.filter((item) => item.id === typeId);
    if (typeTitle.length > 0) setAlertTitle(typeTitle[0].name);
    else setAlertTitle("Ελεγχόμενη Στάθμευση");

    getDetailsAlert().catch((error) => console.warn(error));
  }, [search]);

  const getDetailsAlert = async () => {
    const alertId = new URLSearchParams(search).get("alertId");
    const response = await fetch(
      `https://zitsa.smartiscity.gr/api/api.php?func=alertdetails&alert_id=${alertId}`
    ).catch((error) => console.warn(error));

    if (response === undefined || !response.ok) return;

    const data = await response.json();
    if (data.length > 0) setAlertDetails(data[0]);
  };

  return (
    <div className={classes.info_container}>
      <VerticalTitlte title={alertTitle} icon={null} styleIcon={"red"} />
      {/*<VerticalInfoContent />*/}
      <VerticalAlertContent alertDetails={alertDetails} />
      <VerticalSwitch alertDetails={alertDetails} />
    </div>
  );
};

export default VerticalAlerts;
