import classes from "./SearchTable.module.css";
import "date-fns";
import elLocale from "date-fns/locale/el";
import DateFnsUtils from "@date-io/date-fns";
import { useState } from "react";
import StyledKeyboardDatePicker from "./UI/StyleKeyboardDatePicker";

import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LoadingClock } from "../UI/spinners/LoadingSpinners";

const SearchTable = () => {
  const [selectedDate, setSelectedDate] = useState(
    new Date(Date.now() - 1000 * 60 * 60 * 24 * 7)
  );
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date(Date.now()));

  const handleDateChange = (date) => {
    if (date <= selectedDateEnd) setSelectedDate(date);
    else setSelectedDate(selectedDateEnd);
  };

  const handleDateChangeEnd = (date) => {
    if (date >= selectedDate) setSelectedDateEnd(date);
    else setSelectedDateEnd(selectedDate);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    console.log(selectedDate.getTime(), selectedDateEnd.getTime());
  };
  return (
    <form onSubmit={submitHandler} className={classes.search_form}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={elLocale}>
        <Grid container justifyContent="space-around">
          <StyledKeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            views={["year", "month", "date"]}
            label="Από"
            format="dd/MM/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            className={classes.quick_reg_datepicker}
            InputProps={{
              style: {
                fontSize: 18,
                height: 50,
                color: "white",
              },
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />

          <StyledKeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            views={["year", "month", "date"]}
            label="εως"
            format="dd/MM/yyyy"
            value={selectedDateEnd}
            onChange={handleDateChangeEnd}
            className={classes.quick_reg_datepicker}
            InputProps={{
              style: {
                fontSize: 18,
                height: 50,
                color: "white",
              },
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>

      <div className={classes.submit_date_picks}>
        <button type={"submit"}>
          <span>Υπολογισμός</span>
          <LoadingClock />
        </button>
      </div>
    </form>
  );
};

export default SearchTable;
