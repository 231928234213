import classes from "./PopupContainer.module.css";
import { Fragment, useState } from "react";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import PopupMenuVars from "../../components/UI/popupmenu/PopupMenuVars";
import Popover from "@material-ui/core/Popover";

const PopupContainer = (props) => {
  const [anchorEl_1, setAnchorEl_1] = useState(null);

  const handleClickListItem2 = (event) => {
    setAnchorEl_1(event.currentTarget);
  };

  const handleClose = () => setAnchorEl_1(null);
  return (
    <Fragment>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClickListItem2}
        className={props.className}
      >
        <Badge
          // badgeContent={props.filtersVars.length}
          max={999}
          color="secondary"
          style={{ textTransform: "none" }}
        >
          Μεταβλητή&nbsp;
          <span
            className={
              anchorEl_1
                ? [classes.rotate, classes.animation].join(" ")
                : classes.rotate
            }
          >
            &#8250;
          </span>
        </Badge>
      </Button>
      <Popover
        id={"simple-popover"}
        open={Boolean(anchorEl_1)}
        anchorEl={anchorEl_1}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <PopupMenuVars
          varsList={[]}
          setVars={() => console.log("test")}
          filtersVars={() => console.log("test 2")}
          close={handleClose}
          clearVars={() => console.log("clear vars")}
        />
      </Popover>
    </Fragment>
  );
};

export default PopupContainer;
