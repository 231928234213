import classes from "./Header.module.css";
import { useHistory } from "react-router-dom";
import city from "./../../../assets/images/city.png";

const city_logo = city;
const Header = () => {
  const history = useHistory();

  const redirectHandler = () => {
    if (history.location.pathname !== "/") history.push("/");
  };

  return (
    <div className={[classes.header, classes.flexContainer].join(" ")}>
      <div className={classes.app_logo}>
        <img
          src={city_logo}
          alt="smartiscity logo"
          width="100%"
          onClick={redirectHandler}
        />
      </div>
    </div>
  );
};

export default Header;