import classes from "./SubTitle.module.css";
import React from "react";

const SubTitle = (props) => {
  return (
    <div
      className={[classes.subTitle_placeholder, props.className].join(" ")}
      //style={props.style}
    >
      <p>{props.children}</p>
    </div>
  );
};

export default SubTitle;
