import React from "react";
import classes from "./Home.module.css";
import WelcomeMessage from "./../containers/side_placeholder/WelcomeMessage";
import BoxPlaceholder from "./../containers/box_placeholder/BoxPlaceholder";
import HorizontalSidePanel from "../components/UI/panel/HorizontalSidePanel";
import HorizontalGraph from "../components/forms/horizontal/HorizontalGraph";
import Tableau from "../components/forms/Tableau";

const Home = () => {
  return (
    <div className={classes.home_container}>
      <HorizontalSidePanel
        open={false}
        className={classes.horizontal_side_panel_fs}
      >
        <HorizontalGraph />
      </HorizontalSidePanel>
      {/*<HorizontalSidePanel*/}
      {/*  open={false}*/}
      {/*  className={classes.horizontal_side_panel_tableau}*/}
      {/*>*/}
      {/*  <Tableau />*/}
      {/*</HorizontalSidePanel>*/}
      <WelcomeMessage municipality={"Φλώρινας"} />
      <BoxPlaceholder />
    </div>
  );
};

export default Home;
