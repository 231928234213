import classes from "./SearchInput.module.css";
import { InputOnChange } from "./Input";
import search_icon from "../../../assets/icons/other/search_icon_24x24.png";

const SearchInput = (props) => {
  const searchInputHandler = (inputValue) => console.log(inputValue);

  return (
    <div className={classes.input_search_bundle}>
      <img src={search_icon} alt="search icon" />
      <InputOnChange
        inputPlaceHolder={"Αναζήτηση"}
        type="search"
        setSearch={searchInputHandler}
        title="search"
      />
    </div>
  );
};

export default SearchInput;
